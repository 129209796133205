import { BaseController } from './baseController'

export default class BotController extends BaseController {
  urlBase = '/bots'

  /* BOT REQUESTS */

  async registerBot (body) {
    const response = await this._post(this.urlBase, body)
    return response.body
  }

  async getAllUserBots () {
    return this._get(this.urlBase)
  }

  async getProjectsBots (project) {
    return this._get(`${this.urlBase}?projects=${project}`)
  }

  async getReport (days) {
    return this._get(`${this.urlBase}/report?days=${days}`)
  }

  async getBot (id) {
    return this._get(`${this.urlBase}/${id}`)
  }

  async checkBotToken (botToken) {
    return await this._get(`${this.urlBase}/${botToken}/check`)
  }

  async deleteBot (id) {
    return this._delete(`${this.urlBase}/${id}`)
  }

  async getChannelsBot (id) {
    return this._get(`${this.urlBase}/${id}/channels`)
  }

  async getChannelBot (id, chatId) {
    return this._get(`${this.urlBase}/${id}/channels/${chatId}`)
  }

  async saveWelcomeMessage (id, chatId, welcomeMessage) {
    return this._put(
      `${this.urlBase}/${id}/channels/${chatId}/welcome-message`,
      welcomeMessage
    )
  }

  async updateChannel (id, chatId, body) {
    return this._put(`${this.urlBase}/${id}/channels/${chatId}`, body)
  }

  async getChannelsMembersBot (id, chatId) {
    return this._get(`${this.urlBase}/${id}/channels/${chatId}/members`)
  }

  async getChannelsHistoryBot (id, chatId) {
    return this._get(`${this.urlBase}/${id}/channels/${chatId}/history`)
  }

  async getChannelsHistoryBotReport (id) {
    return this._get(`${this.urlBase}/${id}/channels/history/report`)
  }

  async getChannelsHistoryBotReportByChat (id, chatId) {
    return this._get(`${this.urlBase}/${id}/channels/${chatId}/history/report`)
  }

  async getBotInfo (id) {
    return this._get(`${this.urlBase}/${id}/summary`)
  }

  async getChannelAcessList (id, chatId) {
    return this._get(`${this.urlBase}/${id}/channels/${chatId}/access-list`)
  }

  async manageGroupAccess (id, chatId, data) {
    //status must be "ALLOWED" OR "BLOCKED"
    const body = {
      ...data
    }
    return this._post(
      `${this.urlBase}/${id}/channels/${chatId}/access-list`,
      body
    )
  }

  /* INTENTS BOT REQUESTS */

  async registerIntents (id, intents) {
    const body = {
      intents: intents
    }
    return this._post(`${this.urlBase}/${id}/intents`, body)
  }

  async getIntents (id) {
    return this._get(`${this.urlBase}/${id}/intents`)
  }

  async sendIntentsAnswer (id, data) {
    const body = {
      question: data
    }
    return this._post(`${this.urlBase}/${id}/intents/answer`, body)
  }

  async generateQuestions (id, intentId, question, number) {
    const body = {
      question,
      number
    }
    return this._post(
      `${this.urlBase}/${id}/intents/${intentId}/generate-questions`,
      body
    )
  }

  async extractQuestions (id, intentId, text, number) {
    const body = {
      text,
      number
    }
    return this._post(
      `${this.urlBase}/${id}/intents/${intentId}/extract-questions`,
      body
    )
  }

  /* MESSAGES BOT REQUESTS */

  async sendMessage (id, body) {
    return this._post(`${this.urlBase}/${id}/messages`, body)
  }

  async getMessages (id, chatId, limit, sort, skip) {
    return this._get(
      `${this.urlBase}/${id}/messages?chatId=${chatId}&limit=${limit}&sort=${sort}&skip=${skip}`
    )
  }

  async sendMessageImpersonating (id, data) {
    const body = {
      chatId: data.chatId,
      message: data.message,
      options: {
        impersonate: true
      }
    }
    return this._post(`${this.urlBase}/${id}/messages`, body)
  }

  async sendLogout (id, data) {
    const body = {
      chatId: data.chatId,
      message: '',
      options: {
        logoff: true
      }
    }
    return this._post(`${this.urlBase}/${id}/messages`, body)
  }

  /* CHATS BOT REQUESTS */

  async getChats (id) {
    return this._get(`${this.urlBase}/${id}/chats`)
  }

  async getChat (id, userId) {
    return this._get(`${this.urlBase}/${id}/chats/${userId}`)
  }

  async getChatsReport (id) {
    return this._get(`${this.urlBase}/${id}/chats/report`)
  }

  async updateTags (id, userId, tags) {
    const body = {
      tags: tags
    }
    return this._put(`${this.urlBase}/${id}/chats/${userId}/tagging`, body)
  }

  async addChatTag (id, userId, tags) {
    const body = {
      tags: tags
    }
    return this._post(`${this.urlBase}/${id}/chats/${userId}/tagging`, body)
  }

  async removeChatTag (id, userId, tag) {
    const body = {
      tag: tag
    }
    return this._delete(`${this.urlBase}/${id}/chats/${userId}/tagging`, body)
  }

  async importContacts (id, body) {
    return this._post(`${this.urlBase}/${id}/chats/import`, body)
  }

  /* WEBHOOKS BOT REQUESTS */

  async getWebhooks (id) {
    return this._get(`${this.urlBase}/${id}/webhooks`)
  }

  async saveWebhooks (id, body) {
    return this._post(`${this.urlBase}/${id}/webhooks`, body)
  }

  async updateWebhooks (id, idWebhook, body) {
    return this._put(`${this.urlBase}/${id}/webhooks/${idWebhook}`, body)
  }

  async deleteWebhooks (id, idWebhook) {
    return this._delete(`${this.urlBase}/${id}/webhooks/${idWebhook}`)
  }

  /* CAMPAIGNS BOT REQUESTS */

  async getCampaigns (id) {
    return this._get(`${this.urlBase}/${id}/campaigns`)
  }

  async registerCampaign (id, body) {
    return this._post(`${this.urlBase}/${id}/campaigns`, body)
  }

  async getCampaign (id, campaignId) {
    return this._get(`${this.urlBase}/${id}/campaigns/${campaignId}`)
  }

  async getCampaignTargets (id, campaignId) {
    return this._get(`${this.urlBase}/${id}/campaigns/${campaignId}/targets`)
  }

  async stopCampaign (id, campaignId) {
    return this._post(`${this.urlBase}/${id}/campaigns/${campaignId}/stop`)
  }

  async pauseCampaign (id, campaignId) {
    return this._post(`${this.urlBase}/${id}/campaigns/${campaignId}/pause`)
  }

  async resumeCampaign (id, campaignId) {
    return this._post(`${this.urlBase}/${id}/campaigns/${campaignId}/resume`)
  }

  /* TAGS BOT REQUESTS */

  async getTags (id) {
    return this._get(`${this.urlBase}/${id}/tags`)
  }

  /* TAGS BOT REQUESTS */

  async generateMessage (id, body) {
    return this._post(`${this.urlBase}/${id}/generate-message`, body)
  }

  async restartWhatsapp (id) {
    return this._get(`${this.urlBase}/${id}/restart`)
  }
}
