import BotController from "@/controllers/botController";
import store from "@/store";
import Vue from "vue";
import VueRouter from "vue-router";
const botController = new BotController();
Vue.use(VueRouter);

const getRootBreadCrumb = async (text, name) => {
  return [
    {
      text: text,
      to: { name: name },
    },
  ];
};

const getBotBreadCrumb = async (id) => {
  const { body } = await botController.getBot(id);
  return [
    ...(await getRootBreadCrumb("Conexões", "Conexões")),
    {
      text: body.data?.first_name,
      to: {
        name: "bot-detail",
        params: {
          id: id,
        },
      },
    },
  ];
};

const getIntentsBreadCrumb = async (id) => {
  return [
    ...(await getBotBreadCrumb(id)),
    {
      text: "Treinamento",
      to: {
        name: "manage-intents",
        params: {
          id: id,
        },
      },
    },
  ];
};

const getCampaignsBreadCrumb = async (id) => {
  return [
    ...(await getBotBreadCrumb(id)),
    {
      text: "Campanhas",
      to: {
        name: "campaigns",
        params: {
          id: id,
        },
      },
    },
  ];
};

const getCampaignBreadCrumb = async (id, campaignId) => {
  const { body } = await botController.getCampaign(id, campaignId);
  return [
    ...(await getCampaignsBreadCrumb(id)),
    {
      text: body.title,
      to: {
        name: "campaign",
      },
    },
  ];
};

const getCampaignRegisterBreadCrumb = async (id) => {
  return [
    ...(await getCampaignsBreadCrumb(id)),
    {
      text: "Nova campanha",
      to: {
        name: "register-campaign",
      },
    },
  ];
};

const getHistoryBreadCrumb = async (id, chatId) => {
  const { body } = await botController.getChannelBot(id, chatId);
  return [
    ...(await getBotBreadCrumb(id)),
    {
      text: body.title,
      to: {
        name: "history-view",
        params: {
          id: id,
          chatId: chatId,
        },
      },
    },
  ];
};

const getLeadBreadCrumb = async (id, userId) => {
  return [
    ...(await getBotBreadCrumb(id)),
    {
      text: "Falar com contato",
      to: {
        name: "contact-lead",
        params: {
          id: id,
          userid: userId,
        },
      },
    },
  ];
};

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/",
      name: "home",
      component: () => import("@/views/HomeView.vue"),
      meta: { requiresAuth: true },
    },
    {
      path: "/login",
      name: "login",
      component: () => import("@/views/LoginView.vue"),
      beforeEnter: (to, from, next) => {
        const token = localStorage.getItem("token");
        const user = localStorage.getItem("user");
        const isAuthenticated = token && user;
        if (isAuthenticated) {
          next({
            name: "/",
          });
        } else {
          next();
        }
      },
    },
    {
      path: "/forgot-password",
      name: "forgot-password",
      component: () => import("@/views/ForgotPasswordView.vue"),
    },
    {
      path: "/register",
      nome: "register",
      component: () => import("@/views/RegisterView.vue"),
    },
    {
      path: "/verify-email",
      nome: "verify-email",
      component: () => import("@/views/VerifyEmailView.vue"),
    },
    {
      path: "/confirm-email",
      nome: "confirm-email",
      component: () => import("@/views/ConfirmEmailView.vue"),
    },
    {
      path: "/reset-password",
      name: "reset-password",
      component: () => import("@/views/ResetPasswordView.vue"),
    },
    {
      path: "/logout",
      name: "logout",
      beforeEnter: (to, from, next) => {
        localStorage.removeItem("token");
        localStorage.removeItem("user");
        store.commit("clearStore");
        next({ name: "login" });
      },
    },
    {
      path: "/bots",
      name: "Conexões",
      component: () => import("@/views/BotsView.vue"),
      meta: {
        requiresAuth: true,
        async breadCrumb() {
          return [
            {
              text: "bot",
              to: { name: "bot" },
            },
          ];
        },
      },
    },
    {
      path: "/bots/register",
      name: "BotRegister",
      component: () => import("@/views/BotView.vue"),
      meta: {
        requiresAuth: true,
        async breadCrumb() {
          return [
            {
              text: "conexões",
              to: { name: "Conexões" },
            },
            {
              text: "nova conexão",
              to: { name: "BotRegister" },
            },
          ];
        },
      },
    },
    {
      path: "/bot/:id",
      name: "bot-detail",
      component: () => import("@/views/BotDetailsView.vue"),
      meta: {
        requiresAuth: true,
        async breadCrumb(route) {
          const id = route.params.id;
          return getBotBreadCrumb(id);
        },
      },
    },
    {
      path: "/bot/:id/intent",
      name: "manage-intents",
      component: () => import("@/views/ManageIntentsView.vue"),
      meta: {
        requiresAuth: true,
        async breadCrumb(route) {
          const id = route.params.id;
          return await getIntentsBreadCrumb(id);
        },
      },
    },
    {
      path: "/bot/:id/campaigns",
      name: "campaigns",
      component: () => import("@/views/CampaignsView.vue"),
      meta: {
        requiresAuth: true,
        async breadCrumb(route) {
          const id = route.params.id;
          return await getCampaignsBreadCrumb(id);
        },
      },
    },
    {
      path: "/bot/:id/campaigns/register-campaign",
      name: "register-campaign",
      component: () => import("@/views/CampaignRegisterView.vue"),
      meta: {
        requiresAuth: true,
        async breadCrumb(route) {
          const id = route.params.id;
          return await getCampaignRegisterBreadCrumb(id);
        },
      },
    },
    {
      path: "/bot/:id/campaigns/duplicate-campaign/:campaignId",
      name: "duplicate-campaign",
      component: () => import("@/views/CampaignRegisterView.vue"),
      meta: {
        requiresAuth: true,
        async breadCrumb(route) {
          const id = route.params.id;
          return await getCampaignRegisterBreadCrumb(id);
        },
      },
    },
    {
      path: "/bot/:id/campaigns/:campaignId",
      name: "campaign",
      component: () => import("@/views/CampaignDetailsView.vue"),
      meta: {
        requiresAuth: true,
        async breadCrumb(route) {
          const id = route.params.id;
          const campaignId = route.params.campaignId;
          return await getCampaignBreadCrumb(id, campaignId);
        },
      },
    },
    {
      path: "/bot/:id/channels/:chatId/config",
      name: "history-view",
      component: () => import("@/views/ConfigView.vue"),
      meta: {
        requiresAuth: true,
        async breadCrumb(route) {
          const id = route.params.id;
          const chatId = route.params.chatId;

          return await getHistoryBreadCrumb(id, chatId);
        },
      },
    },
    {
      path: "/bot/:id/lead/:userid",
      name: "contact-lead",
      component: () => import("@/views/ContactLeadView.vue"),
      meta: {
        requiresAuth: true,
        async breadCrumb(route) {
          const id = route.params.id;
          const userId = route.params.userid;
          return await getLeadBreadCrumb(id, userId);
        },
      },
    },
    {
      path: "/user",
      name: "profile",
      component: () => import("@/views/ProfileView.vue"),
      meta: {
        requiresAuth: true,
        async breadCrumb() {
          return [
            {
              text: "Usuário",
              to: { name: "profile" },
            },
          ];
        },
      },
    },
    {
      path: "/lead-list",
      nome: "lead-list",
      component: () => import("@/views/LeadListView.vue"),
      meta: {
        requiresAuth: true,
        async breadCrumb() {
          return [
            {
              text: "Contatos",
              to: { name: "" },
            },
          ];
        },
      },
    },
    {
      path: "/users",
      name: "users",
      component: () => import("@/views/UsersView.vue"),
      meta: {
        requiresAuth: true,
        async breadCrumb() {
          return [
            {
              text: "Usuários",
              to: { name: "users" },
            },
          ];
        },
      },
    },
    {
      path: "/users/register",
      name: "register-user",
      component: () => import("@/views/UserView.vue"),
      meta: {
        requiresAuth: true,
        async breadCrumb() {
          return [
            {
              text: "Usuários",
              to: { name: "users" },
            },
            {
              text: "Novo usuário",
              to: { name: "register-user" },
            },
          ];
        },
      },
    },
    {
      path: "/users/:id/edit",
      name: "edit-user",
      component: () => import("@/views/UserView.vue"),
      meta: {
        requiresAuth: true,
        async breadCrumb() {
          return [
            {
              text: "Usuários",
              to: { name: "users" },
            },
            {
              text: "Editar usuário",
              to: { name: "edit-user" },
            },
          ];
        },
      },
    },
    {
      path: "/projects",
      name: "projects",
      component: () => import("@/views/ProjectsView.vue"),
      meta: {
        requiresAuth: true,
        async breadCrumb() {
          return [
            {
              text: "Projetos",
              to: { name: "projects" },
            },
          ];
        },
      },
    },
    {
      path: "/projects/register",
      name: "register-project",
      component: () => import("@/views/ProjectView.vue"),
      meta: {
        requiresAuth: true,
        async breadCrumb() {
          return [
            {
              text: "Projetos",
              to: { name: "projects" },
            },
            {
              text: "Novo projeto",
              to: { name: "register-project" },
            },
          ];
        },
      },
    },
    {
      path: "/projects/:projectId",
      name: "project",
      component: () => import("@/views/ProjectView.vue"),
      meta: {
        requiresAuth: true,
        async breadCrumb() {
          return [
            {
              text: "Projetos",
              to: { name: "projects" },
            },
            {
              text: "Detalhes do projeto",
              to: { name: "project" },
            },
          ];
        },
      },
    },
    {
      path: "/projects/:projectId/edit",
      name: "edit-project",
      component: () => import("@/views/ProjectView.vue"),
      meta: {
        requiresAuth: true,
        async breadCrumb() {
          return [
            {
              text: "Projetos",
              to: { name: "projects" },
            },
            {
              text: "Editar projeto",
              to: { name: "edit-project" },
            },
          ];
        },
      },
    },
    {
      path: "/flows",
      name: "flows",
      component: () => import("@/views/FlowsView.vue"),
      meta: {
        requiresAuth: true,
        async breadCrumb() {
          return [
            {
              text: "Fluxos",
              to: { name: "flows" },
            },
          ];
        },
      },
    },
    {
      path: "/flows/register",
      name: "flows-register",
      component: () => import("@/views/FlowView.vue"),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/flows/:id/edit",
      name: "flows-edit",
      component: () => import("@/views/FlowView.vue"),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/service-queues",
      name: "service-queues",
      component: () => import("@/views/ServiceQueuesView.vue"),
      meta: {
        requiresAuth: true,
        async breadCrumb() {
          return [
            {
              text: "Filas de atendimento",
              to: { name: "service-queues" },
            },
          ];
        },
      },
    },
    {
      path: "/service-queues/register",
      name: "register-service-queues",
      component: () => import("@/views/ServiceQueueView.vue"),
      meta: {
        requiresAuth: true,
        async breadCrumb() {
          return [
            {
              text: "Filas de atendimento",
              to: { name: "service-queues" },
            },
            {
              text: "Adicionar fila de atendimento",
              to: { name: "register-service-queues" },
            },
          ];
        },
      },
    },
    {
      path: "/service-queues/:id",
      name: "service-queue",
      component: () => import("@/views/ServiceQueueView.vue"),
      meta: {
        requiresAuth: true,
        async breadCrumb() {
          return [
            {
              text: "Filas de atendimento",
              to: { name: "service-queues" },
            },
            {
              text: "Detalhes da fila de atendimento",
              to: { name: "service-queue" },
            },
          ];
        },
      },
    },
    {
      path: "/service-queues/:id/edit",
      name: "edit-service-queue",
      component: () => import("@/views/ServiceQueueView.vue"),
      meta: {
        requiresAuth: true,
        async breadCrumb() {
          return [
            {
              text: "Filas de atendimento",
              to: { name: "service-queues" },
            },
            {
              text: "Editar fila de atendimento",
              to: { name: "edit-service-queue" },
            },
          ];
        },
      },
    },
    {
      path: "/intents",
      name: "intents",
      component: () => import("@/views/IntentsView.vue"),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/intents/register",
      name: "register-intent",
      component: () => import("@/views/ManageIntentsView.vue"),
      meta: {
        requiresAuth: true,
        async breadCrumb() {
          return [
            {
              text: "Bases de treinamentos",
              to: { name: "intents" },
            },
            {
              text: "Novo treinamento",
              to: { name: "register-intent" },
            },
          ];
        },
      },
    },
    {
      path: "/intents/:id",
      name: "intent",
      component: () => import("@/views/ManageIntentsView.vue"),
      meta: {
        requiresAuth: true,
        async breadCrumb() {
          return [
            {
              text: "Bases de treinamentos",
              to: { name: "intents" },
            },
            {
              text: "Detalhes do treinamento",
              to: { name: "intent" },
            },
          ];
        },
      },
    },
    {
      path: "/chats",
      name: "chat",
      component: () => import("@/views/ChatView.vue"),
      meta: {
        requiresAuth: true,
        async breadCrumb() {
          return [
            {
              text: "Atendimento",
              to: { name: "chat" },
            },
          ];
        },
      },
    },
    {
      path: "/sale-funnels",
      name: "sale-funnels",
      component: () => import("@/views/SaleFunnelsView.vue"),
      meta: {
        requiresAuth: true,
        async breadCrumb() {
          return [
            {
              text: "Funis de vendas",
              to: { name: "sale-funnels" },
            },
          ];
        },
      },
    },
    {
      path: "/sale-funnels/register",
      name: "register-sale-funnel",
      component: () => import("@/views/SaleFunnelView.vue"),
      meta: {
        requiresAuth: true,
        async breadCrumb() {
          return [
            {
              text: "Novo funil de vendas",
              to: { name: "register-sale-funnel" },
            },
          ];
        },
      },
    },
    {
      path: "/sale-funnels/:id",
      name: "sale-funnel",
      component: () => import("@/views/SaleFunnelView.vue"),
      meta: {
        requiresAuth: true,
        async breadCrumb() {
          return [
            {
              text: "Funil de vendas",
              to: { name: "sale-funnel" },
            },
          ];
        },
      },
    },
    {
      path: "/dashboards",
      name: "dashboards",
      component: () => import("@/views/DashboardsView.vue"),
      meta: {
        requiresRole: ['admin', 'manager'] ,
        requiresAuth: true,
        async breadCrumb() {
          return [{ text: "Dashboards", to: { name: "dashboards" } }];
        },
      },
    },
    {
      path: "/unauthorized",
      name: "unauthorized",
      component: () => import("../views/UnauthorizedPage.vue"),
    },

  ],
});

router.beforeEach((to, from, next) => {
  const token = localStorage.getItem("token");
  const user = localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")) : null;
  const isAuthenticated = token && user;
  const userRole = user ? user.role : null; 
  window.scrollTo(0, 0);

  const publicRoutes = [
    "login",
    "forgot-password",
    "register",
    "verify-email",
    "confirm-email",
    "reset-password"
  ];

  if (publicRoutes.includes(to.name)) {
    return next();
  }

  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!isAuthenticated) {
      return next({ name: "login" });
    }

    if (userRole === "user" && ! "chat".includes(to.name)) {
      return next({ name: "unauthorized" });
    }

    if (userRole === "admin" || userRole === "manager") {
      return next();
    }
  }

  next();
});



export default router;
